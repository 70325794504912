import "./CityBuild.scss";
import React from "react";

function CityBuild() {

    return (
        <div id="CityBuild" className={"container"}>
            <div className={"background-video opacity-25"}>
                <img src="/assets/images/citybuild.png" alt="CityBuild"/>
            </div>
            <section
                className="text-center d-flex align-items-center justify-content-evenly custom-section section-start">
                <div className="text text-box">
                    <h1>CityBuild</h1>
                    <div>
                        <h2>Was ist CityBuild?</h2>
                        <p>CityBuild ist ein beliebter Spielmodus in Minecraft, bei dem Spieler die Möglichkeit
                            haben,
                            ihre
                            eigenen Städte zu bauen und zu verwalten. Im Gegensatz zu anderen Spielmodi wie Survival
                            oder
                            Creative bietet CityBuild eine strukturierte Umgebung, in der Spieler Grundstücke
                            erwerben,
                            Gebäude errichten und Ressourcen handeln können.</p>
                    </div>
                    <div>
                        <h2>Warum CityBuild auf QuantumRealms?</h2>
                        <p>Unser CityBuild-Server bietet eine einzigartige Kombination aus Kreativität, Struktur und
                            Gemeinschaft. Mit einer engagierten Community, regelmäßigen Updates und Events sowie
                            einem
                            engagierten Team von Moderatoren und Supportern streben wir danach, die beste
                            CityBuild-Erfahrung in Minecraft zu bieten. <br/>
                            Trete uns bei und werde Teil der
                            QuantumRealms-Welt!</p>
                    </div>
                </div>
                <div className="text text-box">
                    <h2>Unsere Features</h2>
                    <div><strong>Grundstücke:</strong> <br/> Sichere dir dein eigenes Grundstück und beginne mit dem
                        Bau
                        deiner Traumstadt. Jedes Grundstück kann individuell gestaltet und erweitert werden.
                    </div>
                    <div><strong>Wirtschaftssystem:</strong> <br/> Nutze unser umfassendes Wirtschaftssystem, um
                        Ressourcen
                        zu handeln, Jobs anzunehmen und Geld zu verdienen. Kaufe und verkaufe Items im Auktionshaus
                        oder bei anderen Spielern.
                    </div>
                    <div><strong>Community Events:</strong> <br/> Nimm an regelmäßigen Community-Events teil, bei
                        denen du
                        Preise gewinnen und dich mit anderen Spielern messen kannst. Unsere Events fördern den
                        Zusammenhalt und die Zusammenarbeit innerhalb der Community.
                    </div>
                    <div><strong>Spieler-Ränge:</strong> <br/> Erreiche durch aktives Spielen höhere Ränge und
                        schalte
                        exklusive Belohnungen und Fähigkeiten frei. Zeige deinen Fortschritt und werde zu einem
                        angesehenen Mitglied der QuantumRealms-Community.
                    </div>
                    <div><strong>Sicherheitsfunktionen:</strong> <br/> Dank unserer Anti-Griefing-Maßnahmen und
                        zuverlässiger Moderation kannst du sicher sein, dass deine Bauwerke und Ressourcen geschützt
                        sind.
                    </div>
                </div>
            </section>

        </div>
    );
}

export default CityBuild;