import "./Footer.css";

function Footer() {
    return (
        <div id="Footer">
            <div className="FooterContent">
                <iframe title="discord" src="https://discord.com/widget?id=1249905903369719879&theme=dark" width={200 + (window.innerWidth * 0.1)} height="156"
                        frameBorder="0"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
            </div>
            <div className={"FooterCopyright"}>
                Copyright © {new Date().getFullYear()} QuantumRealms MBUXN - All rights reserved
            </div>
        </div>
    );
}

export default Footer;