import React, {useEffect, useState} from 'react';
import "./Header.scss";

import {Link} from "react-router-dom";
import {generateUUID} from "../../../utils/generateUUID";

function Header() {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [headerLinks, setHeaderLinks] = useState([
        {
            title: "Home",
            link: "/"
        },
        {
            title: "Team",
            link: "/team"
        },
        {
            title: "CityBuild",
            link: "/citybuild"
        }
    ])

    const reloadActiveStates = () => {
        const temp = headerLinks;
        setHeaderLinks([{
            title: "Loading",
            link: "#"
        }]);
        setTimeout(() => {
            setHeaderLinks(temp)
        },10)
    }

    useEffect(() => {
        setInterval(() => {
            setScreenWidth(window.innerWidth);
        }, 100)
    }, []);

    return (
        <div id="HeaderNav">
            <div className={"HeaderNavGroup HeaderNavGroupLeft"}>
                <div className={"HeaderNavLogo"}>
                    <Link to={"/"} onClick={reloadActiveStates}>
                        <img src="/logo512.png" alt="logo"/>
                    </Link>
                </div>
            </div>
            <div className={"HeaderNavGroup HeaderNavGroupRight"}>
                {
                    screenWidth > 1000 && <>
                        <nav className="navbar navbar-expand-lg" key={generateUUID()}>
                            <ul className="navbar-nav me-auto my-2 my-lg-0 gap-3">
                                {
                                    headerLinks.map((x) => (
                                    <li className="nav-item" key={x.title}>
                                        {
                                            x.link.includes("http") ?
                                                <a className={`nav-link ${window.location.pathname === x.link ? "active" : ""}`}
                                                   href={x.link} target="_blank" rel="noopener noreferrer"  key={generateUUID()}>{x.title}</a>
                                                :
                                                <Link
                                                    className={`nav-link ${window.location.pathname === x.link ? "active" : ""}`}
                                                    to={x.link}
                                                    onClick={reloadActiveStates} key={generateUUID()}>{x.title}</Link>
                                        }
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </> || <>
                        <nav className="collapse navbar navbar-expand-lg position-absolute" id="navbarToggle" key={generateUUID()}>
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll gap-2">
                                {
                                    headerLinks.map((x) => <li className="nav-item" key={generateUUID()}>
                                        {
                                            x.link.includes("http") && <>
                                                <a className={`nav-link ${window.location.pathname === x.link && "active"}`}
                                                   href={x.link} target={"_blank"}>{x.title}</a>
                                            </> || <>
                                                <Link
                                                    className={`nav-link ${window.location.pathname === x.link && "active"}`}
                                                    to={x.link} onClick={reloadActiveStates}>{x.title}</Link>
                                            </>
                                        }

                                    </li>)
                                }
                            </ul>
                        </nav>
                        <nav className="navbar">
                            <div className="container-fluid">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarToggle"
                                        aria-controls="navbarToggle" aria-expanded="false"
                                        aria-label="Toggle navigation">
                                    <i className="bi-list"></i>
                                </button>
                            </div>
                        </nav>
                    </>
                }
            </div>
        </div>
    );
}

export default Header;