import React, {useEffect, useState} from 'react';
import './Team.scss';

const Team = () => {
    const teamMembers = {
        Owner: [{
            name: 'Uflax',
            description: 'Inhaber des Projekts!',
            minecraftUsername: 'einfachUflax'
        }],
        Administrator: [{
            name: 'EinfachVeve',
            description: 'Unterstützt euch bei Fragen und hilft gerne!',
            minecraftUsername: 'EinfachVeve'
        }],
        Developer: [{
            name: 'Joniwoch',
            description: 'Er macht so code und so!',
            minecraftUsername: 'joniwoch'
        },{
            name: 'Jan',
            description: 'Er macht so code und so auch!',
            minecraftUsername: 'Jan2808'
        },{
            name: 'Vielleicht ja du!',
            description: 'Melde dich gerne in unserem Discord, wir suchen immer nach engagierten neuen Mitgliedern für QuantumRealms.de!',
            minecraftUsername: ''
        }],
        Moderator: [{
            name: 'Ich_Esse_Burger',
            description: 'Unser Moderator und Supporter der euch unterstützt und gerne Hammer mag!',
            minecraftUsername: 'Ich_Esse_Burger'
        },{
            name: 'Vielleicht ja du!',
            description: 'Melde dich gerne in unserem Discord, wir suchen immer nach engagierten neuen Mitgliedern für QuantumRealms.de!',
            minecraftUsername: ''
        }],
        Supporter: [{
            name: 'DJSunny2000',
            description: 'Hier für euch wenn ihr Fragen oder andere Anliegen habt!',
            minecraftUsername: 'DJSunny2000'
        }],
        Builder: [{
            name: 'Vielleicht ja du!',
            description: ' Melde dich gerne in unserem Discord, wir suchen immer nach engagierten neuen Mitgliedern für QuantumRealms.de!',
            minecraftUsername: ''
        }],
    };

    const [skins, setSkins] = useState({});

    const getUuidByUsername = async (username) => {
        try {
            const usernameToUuidApi = `https://api.minetools.eu/uuid/${username}`;
            let response = await fetch(usernameToUuidApi);
            let data = await response.json();

            return data.id;
        } catch (e) {
            return "None";
        }
    }

    const getSkinByUuid = async (username) => {
        try {
            const skinByUuidApi = `https://visage.surgeplay.com/bust/512/${await getUuidByUsername(username)}`;
            let response = await fetch(skinByUuidApi);

            if (response.status === 400) return `https://visage.surgeplay.com/bust/512/ec561538f3fd461daff5086b22154bce`;
            else return skinByUuidApi;
        } catch (e) {
            return "None";
        }
    }

    const loadSkins = async () => {
        const newSkins = {};
        for (const rank in teamMembers) {
            for (const member of teamMembers[rank]) {
                newSkins[member.minecraftUsername] = await getSkinByUuid(member.minecraftUsername);
            }
        }
        setSkins(newSkins);
    };

    useEffect(() => {
        loadSkins().then(()=>{
            console.log("RELOADED SKINS")
        });
    }, []);

    return (
        <div id={"Team"}>
            <div className={"team-backdropFilter"}>
                <div className="team-overview">
                    {Object.keys(teamMembers).map((rank) => (
                        <div key={rank} className="team-rank">
                            <h2>{rank}</h2>
                            <ul>
                                {teamMembers[rank].map((member, index) => (
                                    <li key={index}>
                                        <div>
                                            <img src={skins[member.minecraftUsername]} alt="playerskin"/>
                                        </div>
                                        <div>
                                            <h3>{member.name}</h3>
                                            <p>{member.description}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Team;